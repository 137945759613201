<template>
  <v-dialog
    overlay-color="rgba(34, 34, 34, 0.27)"
    overlay-opacity="0.8"
    v-model="state"
    max-width="400"
    width="100%"
    persistent
  >
    <v-card>
      <v-card-title class="ft text-md ml-n2">
        <span class="ml-1 ft text-md"
          >Choose a table column to export</span
        ></v-card-title
      >
      <v-divider />

      <v-container class="mt-n4 pa-0">
        <div class="d-flex flex-column flex-full mx-5 my-4">
          <v-switch
            v-for="(field, index) in columns"
            :key="index"
            hide-details
            v-model="fields"
            inset
            color="primary"
            :value="field"
          >
            <template v-slot:label>
              <span class="ft font-weight-medium">{{ field }}</span>
            </template>
          </v-switch>
        </div>
        <v-divider class="mt-5" />
        <div class="d-flex mx-1 flex-row flex-full my-3">
          <v-btn
            small
            @click.stop="
              $store.dispatch('contact/bulkContactDownload', {
                fileName: 'contacts-export.xlsx',
                fields,
              })
            "
            text
            outlined
            class="mr-1 flex-grow-1"
          >
            <span class="ft primary--text font-weight-bold font-size-sm"
              >Export As Excel</span
            >
          </v-btn>
          <v-btn
            small
            text
            tag="a"
            target="_blank"
            outlined
            class="flex-grow-1"
            @click.stop="
              $store.dispatch('contact/bulkContactDownload', {
                fileName: 'contacts.csv',
                fields,
              })
            "
          >
            <span class="ft primary--text font-weight-bold font-size-sm"
              >Export As CSV</span
            >
          </v-btn>
        </div>
      </v-container>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="accent"
          class="text-capitalize text-sm ft"
          text
          @click="action"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable */
export default {
  name: 'BulkExport',
  props: {
    state: {
      type: Boolean,
      required: true,
    },
    loading: Boolean,
  },
  data: () => ({
    file: null,
    columns: ['Name', 'Phone', 'Date Of Birth'],
    fields: ['Name', 'Phone', 'Date Of Birth'],
  }),
  methods: {
    action() {
      this.$emit('actions', { idx: 'download', state: false });
    },
  },
};
</script>

<style scoped></style>
